@import '../variables.css';

.tabs {
  display: flex;
  justify-content: flex-end;
  gap: var(--header-item-gap);
}

.tab {
  cursor: pointer;
  color: #EEEEEE;
  border: none;
  background-color: transparent;
  padding: 0;
}

.tab:hover {
  color: var(--header-item-hover);
}

.tab.active {
  border-bottom: none;
  background-color: transparent;
}