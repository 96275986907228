@import '../variables.css';

.project {
    width: 240px;
    height: 300px;
    background-color: white;
    border: 1px solid #EEEEEE;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.project img{
    width: 100%;
    height: 200px;
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}

.project .text{
  padding: 8px;
}

.slider-container{
  width: 500px;
}