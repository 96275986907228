@import '../variables.css';

.sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--header-item-gap);
}

.icon {
  color: white;
}

.icon:hover {
  color: var(--header-item-hover);
}