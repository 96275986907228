@import '../variables.css';

.header-container {
  top: 0;
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--header-color);
  height: var(--header-height);
  z-index: 1000;
  padding: 0 20px;
  transition: background-color 0.3s ease-in-out;
}

.header-tab .tab {
  font-size: 17px;
}

.header-container.scrolled {
  background-color: #EEEEEE;
}

.header-container.scrolled .tab{
  color: black;
}

.header-container.scrolled .icon{
  color: black;
}